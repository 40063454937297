import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import type { QueryParams } from '../types';
import { getIdArrays } from './get-id-arrays';

export async function fetchAllIds<Instance extends BaseInstance>({
  modelName,
  filters,
  order,
  nested,
  slim,
  ids,
  idAttr = 'id',
}: {
  modelName: ModelName;
  filters?: QueryParams['filters'];
  order?: QueryParams['order'];
  nested?: (keyof Instance)[] | undefined;
  slim?: boolean;
  ids: string[];
  idAttr: string;
}) {
  if (ids.length === 0)
    return {
      list: [],
      meta: {
        page_amount: 1,
        page_size: 0,
        total_amount: 0,
      },
    };

  const promises = getIdArrays<Instance>({
    filters,
    idAttr,
    ids,
    modelName,
    order,
    nested,
    slim,
  });

  const result = await Promise.all(promises);

  return {
    list: result.map((r) => r.list).flat(),
    meta: {
      page_amount: 1,
      page_size: result.length,
      total_amount: result.length,
    },
  };
}

import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { MAX_BYTES_IN_QUERY_PARAMETERS } from '../constants';
import type { QueryParams } from '../types';
import { fetchAllInstances } from './fetch-all-instances';
import { getBytesOfString } from './utils';

export function getIdArrays<Instance extends BaseInstance>({
  filters,
  order,
  nested,
  slim,
  modelName,
  ids,
  idAttr = 'id',
}: {
  filters: QueryParams['filters'];
  order: QueryParams['order'];
  nested?: (keyof Instance)[] | undefined;
  slim?: boolean;
  modelName: ModelName;
  ids: string[];
  idAttr: string;
}) {
  const totalBytesInIdFilter = getBytesOfString(ids.join(','));
  const canFetchAllInOnePage =
    totalBytesInIdFilter <= MAX_BYTES_IN_QUERY_PARAMETERS;

  // if we cant fit all ids in the byte limit, we need to split it in half and try again until we can
  if (!canFetchAllInOnePage) {
    const multipleValues = [
      ids.slice(0, Math.floor(ids.length / 2)),
      ids.slice(Math.floor(ids.length / 2)),
    ];
    let promises: ReturnType<typeof fetchAllInstances>[] = [];

    for (const valueArr of multipleValues) {
      const result = getIdArrays({
        modelName,
        nested,
        filters,
        ids: valueArr,
        order,
        slim,
        idAttr,
      });

      promises = [...promises, ...result];
    }
    return promises;
  } else {
    // here we're under the byte limit, return a promise
    return [
      fetchAllInstances<Instance>({
        filters: {
          [idAttr]: { __in: ids.join(',') },
          ...filters,
        },
        nested,
        modelName,
        slim,
        order,
      }),
    ];
  }
}
